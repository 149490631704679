<script setup lang="ts">
import DragAndDrop from '@/blocks/HomeWrapper/Search/DragAndDrop/DragAndDrop.vue'
import Button from 'primevue/button'
import { computed, ref, watch } from 'vue'
import router from '@/router'
import { autocomplete, deleteSearchHistory, searchHistory } from '@/api/search'
import { useSearch } from '@/stores/search'
import { useImageSearch } from '@/stores/imageSearch'

const emits = defineEmits(['search','closeSearch'])

const searchStore = useSearch()
const searchImageStore = useImageSearch()

const items = ref([])
const historyItems = ref([])
const searchTipsItems = ref([])
const searchTipsBrandsItems = ref([])
const historyItemsForDelete = ref([])

const searchedText = ref('')
const showSuggestions = ref(false)

const searchedImageLink = computed(() => searchImageStore.currentImageLink )
const isButtonLoading = computed(() => searchStore.isDefineFiltersLoading || searchStore.isGetSearchListLoading )


const searchHistoryType = computed(() => {
  if( searchedImageLink?.value && searchedText?.value ) {
    return 'image_and_text'
  } else if( searchedImageLink?.value ) {
    return 'image'
  } else {
    return 'text'
  }
})



async function find() {
  await searchStore.defineFilters(String(searchedText.value))
  await router.push(`/searchList/${searchedText.value}`)
  emits('search')
  emits('closeSearch')
}

const onInput = (event) => {
  if (searchedText.value === '') {
    searchTipsItems.value = []
    showSuggestions.value = false
  } else {
    historyItems.value = []
    search(event)
    showSuggestions.value = true
  }
}

const showHistory = (event) => {
  if (searchedText.value === '') {
    history(5)
    showSuggestions.value = true
  } else {
    showSuggestions.value = true
  }
}

const hideSuggestions = () => {
  // Delay hiding to allow click event on the suggestion to be registered
  setTimeout(() => {
    showSuggestions.value = false
  }, 100)
}

const selectSuggestion = (suggestion: string) => {
  searchedText.value = suggestion
  showSuggestions.value = false
}

const clearSuggestion = () => {
  searchedText.value = ''
  showSuggestions.value = false
}

const clearHistoryItem = (id:string) => {
  historyItemsForDelete.value.push(id)
}

const clearAllHistoryItems = () => {
  historyItemsForDelete.value = historyItems.value.map(item => item.id)
}

const history = async (size) => {
  const response = await searchHistory(5,searchHistoryType.value)
  if (response.data.recent_searches) historyItems.value = response.data.recent_searches
}

const search = async (event) => {
  const response = await autocomplete({
    search_text: searchedText.value,
    suggestions_size: 5,
    suggested_brands_size: 5
  })
  searchTipsItems.value = response.data.suggestions
  searchTipsBrandsItems.value = response.data.suggested_brands
}

watch( historyItemsForDelete, async () => {
 const res = await deleteSearchHistory(historyItemsForDelete.value)
  if ( res.status === 200) {
    await history(5)
  }
},{
  deep:true
})

</script>

<template>
  <div class="search__input__wrapper">
    <div class="top">
      <div class="input-wrp">
        <div class="search__input-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.2501 10.5001C17.2501 11.3865 17.0755 12.2643 16.7363 13.0832C16.3971 13.9021 15.8999 14.6463 15.2731 15.2731C14.6463 15.8999 13.9021 16.3971 13.0832 16.7363C12.2643 17.0755 11.3865 17.2501 10.5001 17.2501C9.61367 17.2501 8.73592 17.0755 7.91698 16.7363C7.09803 16.3971 6.35391 15.8999 5.72712 15.2731C5.10032 14.6463 4.60312 13.9021 4.2639 13.0832C3.92468 12.2643 3.75009 11.3865 3.75009 10.5001C3.75009 8.70988 4.46125 6.99299 5.72712 5.72712C6.99299 4.46125 8.70988 3.75009 10.5001 3.75009C12.2903 3.75009 14.0072 4.46125 15.2731 5.72712C16.5389 6.99299 17.2501 8.70988 17.2501 10.5001ZM16.0201 17.6101C14.2117 19.0141 11.9361 19.676 9.65679 19.4613C7.37744 19.2466 5.26561 18.1712 3.75121 16.4542C2.23682 14.7372 1.43372 12.5075 1.50539 10.2192C1.57707 7.93089 2.51814 5.7559 4.13702 4.13702C5.7559 2.51814 7.93089 1.57707 10.2192 1.50539C12.5075 1.43372 14.7372 2.23682 16.4542 3.75121C18.1712 5.26561 19.2466 7.37744 19.4613 9.65679C19.676 11.9361 19.0141 14.2117 17.6101 16.0201L21.7951 20.2051C21.9056 20.3081 21.9943 20.4323 22.0558 20.5703C22.1172 20.7083 22.1503 20.8573 22.153 21.0083C22.1556 21.1594 22.1279 21.3094 22.0713 21.4495C22.0147 21.5896 21.9305 21.7168 21.8236 21.8236C21.7168 21.9305 21.5896 22.0147 21.4495 22.0713C21.3094 22.1279 21.1594 22.1556 21.0083 22.153C20.8573 22.1503 20.7083 22.1172 20.5703 22.0558C20.4323 21.9943 20.3081 21.9056 20.2051 21.7951L16.0201 17.6101Z"
              fill="#919191"
            />
          </svg>
        </div>

        <input
          class="inp-auto"
          type="text"
          v-model="searchedText"
          @input="onInput"
          @focus="showHistory"
          @blur="hideSuggestions"
          @keydown.enter="find"
        />

        <div v-if="searchedText" class="close__input-icon" @click="clearSuggestion">
          <i class="pi pi-times"></i>
        </div>
      </div>

      <div class="upload-photo-image">
        <DragAndDrop />
      </div>
      <div class="find-btn__wrapper">
        <Button label="Найти" :loading="isButtonLoading" loading-icon="pi pi-spinner" @click="find" />
      </div>
    </div>
    <div v-if="showSuggestions && historyItems.length" class="bottom">
      <div class="history-wrapper">
        <div class="bottom-men-title__wrapper">
          <span class="bottom-menu-history-title">История поиска</span>
          <span class="clear-history-btn"
                @click="clearAllHistoryItems"
          >Очистить</span>
        </div>
        <ul class="history-items-list">
          <li
            class="history-list-item"
            v-for="(suggestion, index) in historyItems"
            :key="index"
          >
            <span class="history-list-item__text"
                  @click="selectSuggestion(suggestion.query)">
              {{ suggestion?.query }}
            </span>
            <i class="pi pi-times"
                @click.stop="clearHistoryItem(suggestion?.id)"
                style="color: rgba(145, 145, 145, 1)"></i>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="showSuggestions && searchTipsItems.length" class="bottom">
      <div class="search-tip__wrapper">
        <ul class="search-list-tip__wrapper">
          <li
            class="search-tip__item"
            v-for="(suggestion, index) in searchTipsItems"
            :key="index"
            @mousedown.prevent="selectSuggestion(suggestion)"
          >
            <span class="history-list-item__text">{{ suggestion }}</span>
          </li>
        </ul>
        <div v-if="searchTipsBrandsItems.length" class="brands-tips">
          <span class="brands-title">Бренды</span>
          <div class="brands-items-list__wrapper">

            <div v-for="brandItem in searchTipsBrandsItems" class="brands-item">
              <img :src="brandItem?.logo_url" class="brand-image" alt="brand-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clear-history-btn {
  color: rgba(26, 33, 51, 1);
  text-decoration: underline;
  cursor: pointer;
}

.clear-history-btn:hover {
  color: rgba(26, 33, 51, 0.6);
}

.clear-history-btn:active {
  color: rgba(26, 33, 51, 0.6);
}

.history-wrapper {
  width: 100%;
}

.brand-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}

.history-items-list {
  margin: 0;
  padding: 0 12px;
  width: 100%;
}
.history-list-item {
  width: 100%;
  display: flex;
  border-radius: 40px;
  align-items: center;
  padding: 0 12px 0 12px;
  justify-content: space-between;
  height: 40px;
}

.history-list-item:hover {
  background: rgba(165, 201, 255, 0.2);
  cursor: pointer;
}

.history-list-item:active {
  background: rgba(165, 201, 255, 0.2);
  cursor: pointer;
}

.search__input__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 3;
  gap: 12px;
  justify-content: center;
  border-radius: 28px;
  height: auto;
  padding: 6px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  transition: height 1s ease;
  box-shadow: 0 4px 13px 0px rgba(0, 0, 0, 0.25);
}

.search__input__wrapper:hover {
  background: rgba(245, 245, 247, 1);
}

.search__input__wrapper:focus-within {
  background: rgba(245, 245, 247, 1);
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
  position: relative;
}

.input-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 28px;
  padding: 6px 6px 6px 12px;
  width: 100%;
}

.input-wrp:focus-within {
  background: white;
  border: 1px solid rgba(215, 217, 219, 1);
}

.search__input-icon {
  width: 24px;
  height: 24px;
}

.inp-auto {
  border: none;
  outline: none;
  width: 100%;
  background: transparent !important;
}

.close__input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40x;
  height: 40px;
  margin-right: 6px;
  cursor: pointer;
}

.bottom-menu-history-title {
  font-size: 16px;
  font-weight: 600;
  line-heigh: 21px;
  color: rgba(26, 33, 51, 1);
  padding: 0 0 0 12px;
}

.bottom-men-title__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 0 12px;
  width: 100%;
  height: 40px;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 6px;
}

.search-tip__wrapper {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.search-list-tip__wrapper {
  width: 100%;
  list-style: none;
  padding: 0;
}

.search-tip__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 12px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}

.search-tip__item:hover {
  background: rgba(165, 201, 255, 0.2);
}

.search-tip__item:active {
  background: rgba(165, 201, 255, 0.2);
}

.history-list-item__text {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  color: rgba(61, 61, 61, 1);
  width: 100%;
  height: 100%;
}

.brands-tips {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 0 8px 12px;
}

.brands-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(26, 33, 51, 1);
}

.brands-items-list__wrapper {
  display: flex;
  gap: 24px;
}

.brands-item {
  height: 96px;
  width: 96px;
  padding: 8px 12px 8px 12px;
  background: rgba(215, 217, 219, 1);
  border-radius: 20px;
}

:deep(.p-button) {
  max-width: 96px;
  max-height: 48px;
  border-radius: 40px;
  padding: 16px 24px;
  border: 1px solid rgba(36, 49, 74, 1);
  background: rgba(36, 49, 74, 1);
}

.search-tip__wrapper::-webkit-scrollbar {
  width: 4px; /* Ширина всего элемента навигации */
}

.search-tip__wrapper::-webkit-scrollbar-track {
  background: transparent; /* Цвет дорожки */
}

.search-tip__wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(145, 145, 145, 1); /* Цвет бегунка */
  border-radius: 100px; /* Округление бегунка */
  border: 1px solid rgba(145, 145, 145, 1); /* Оформление границ бегунка */
}
</style>
