<script setup lang="ts">
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanel from 'primevue/tabpanel'
import SignIn from '@/blocks/Modals/AuthModals/SignInSignUpWrapper/SignIn.vue'
import SignUp from '@/blocks/Modals/AuthModals/SignInSignUpWrapper/SignUp.vue'
import { ref } from 'vue'
import Divider from 'primevue/divider'
import { useAuthStore } from '@/stores/user'

const value = ref('0')

const tabTheme = ref({
  tab: {
    activeColor: 'rgba(48, 48, 48, 1)',
    color: 'rgba(145, 145, 145, 1)',
    borderColor: 'rgba(215, 217, 219, 1)',
    padding: '12px 16px',
    activeBorderColor: 'rgba(48, 48, 48, 1)'
  },
  activeBar: {
    background: 'rgba(48, 48, 48, 1)'
  }
})

const tabs = ref([
  {
    title: 'ВОЙТИ',
    value: '0'
  },
  {
    title: 'СОЗДАТЬ АККАУНТ',
    value: '1'
  }
])

const handleThirdPartyLogin = async (payload) => {
  await useAuthStore().thirdPartyAuth(payload)
}
</script>

<template>
  <div class="sign-in-sign-up-wrapper">
    <Tabs
      v-model:value="value"
      :pt="{
        panelContainer: {
          style: {
            padding: '0'
          }
        }
      }"
      :dt="tabTheme"
    >
      <TabList
        :pt="{
          tabList: {
            style: {
              width: '100%',
              background: 'transparent'
            }
          }
        }"
      >
        <Tab
          v-for="tab in tabs"
          :key="tab.title"
          :value="tab.value"
          :pt="{
            root: {
              style: {
                width: '50%'
              }
            }
          }"
        >
          {{ tab.title }}
        </Tab>
      </TabList>
      <TabPanel value="0">
        <sign-in />
      </TabPanel>
      <TabPanel value="1">
        <sign-up />
      </TabPanel>
    </Tabs>
    <div class="sign-in-bottom-content">
      <div class="sign-in__divider">
        <Divider
          type="solid"
          :pt="{
            content: {
              style: {
                background: 'rgba(245, 245, 247, 1)'
              }
            }
          }"
        >
          <span class="sign-in__divider-text">или войти с помощью</span>
        </Divider>
      </div>
      <div class="sign-in_third_party">
        <div
          class="third-party-auth_item google"
          @click="handleThirdPartyLogin('google')"
        >
          <img
            src="/src/assets/img/Google.svg"
            alt="google auth"
          >
        </div>
        <div
          class="third-party-auth_item vk"
          @click="handleThirdPartyLogin('vk')"
        >
          <img
            src="/src/assets/img/VK.svg"
            alt="vk auth"
          >
        </div>
        <div
          class="third-party-auth_item yandex"
          @click="handleThirdPartyLogin('yandex')"
        >
          <img
            src="/src/assets/img/Yandex.svg"
            alt="yandex auth"
          >
        </div>
      </div>
    </div>
    <div class="have-dont-have-account">
      <p v-if="value === '0'">
        Ещё нет аккаунта ?
        <span
          :style="{
            fontWeight: '600',
            cursor: 'pointer'
          }"
          @click="value = '1'"
        >Создать аккаунт
        </span>
      </p>

      <p v-if="value === '1'">
        Уже есть аккаунт ?
        <span
          :style="{
            fontWeight: '600',
            cursor: 'pointer'
          }"
          @click="value = '0'"
        >Войти</span>
      </p>
    </div>
  </div>
</template>

<style scoped>
.sign-in-sign-up-wrapper {
  padding: 0 40px 40px 40px;
  overflow: auto;
}

.have-dont-have-account {
  display: flex;
  justify-content: center;
}

.sign-in-bottom-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
}

.sign-in__divider {
  width: 100%;
}

.sign-in__divider-text {
  font-size: 16px;
  line-height: 21px;
}

.sign-in_third_party {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
}

.third-party-auth_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 16px;
  border: 1px solid rgba(215, 217, 219, 1);
  border-radius: 12px;
  cursor: pointer;
}
</style>
