<template>
  <div class="home-page__wrapper">
    <header-wrapper :is-mega-menu-visible-immediately="true" />
    <div class="result-page-content-inner">
      <search-page-content-header
        :title="searchedText"
        :items-count="totalQuantity"
      />
      <search-page-content-wrapper />
    </div>
    <subscribe-form />
    <base-footer />
  </div>
</template>

<script setup lang="ts">
import HeaderWrapper from '@/blocks/Header/HeaderWrapper.vue'
import SearchPageContentWrapper from '@/blocks/SearchPage/SearchPageContent/SearchPageContentWrapper.vue'
import SearchPageContentHeader from '@/blocks/SearchPage/SearhPageContentHeader/SearchPageContentHeader.vue'
import SubscribeForm from '@/blocks/HomeWrapper/SubscribeForm/SubscribeForm.vue'
import BaseFooter from '@/blocks/Footer/Footer.vue'
import { computed, onMounted, watch } from 'vue'
import { useSearch } from '@/stores/search'
import { useRoute, useRouter } from 'vue-router'
import { transformFilters } from '@/helpers/createFiltersArrayFromUserMenuForBackendApi'
import { transformFiltersToObjectQuery } from '@/helpers/transformSelectedFiltersToRouterQuerry'
import { parseFiltersFromUrl } from '@/helpers/parseFiltersFromUrl'
import { useImageSearch } from '@/stores/imageSearch'

const search = useSearch()
const route = useRoute()
const router = useRouter()
const imageSearch = useImageSearch()

const searchedText = computed(() => route.params.searchText)
const queryFilters = computed(() => route.query)
const definedFilters = computed(() => search.responseDefineFilters)
const currentFilters  = computed(() => search.currentFilters)
const imageUrl = computed(() => imageSearch.currentImageLink)
const totalQuantity = computed(() => search.totalQuantity)

watch(currentFilters, async () => {
  const trnsfftsrs = transformFilters(currentFilters.value)
  const urlFilters = transformFiltersToObjectQuery(trnsfftsrs)
  if(route.query.page) {
    urlFilters.page = route.query.page
  }
  await router.push({query:urlFilters})
},{
  deep: true,
})

</script>

<style scoped>
.home-page__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 245, 247, 1);
}

.result-page-content-inner {
  margin-top: 190px;
  padding: 0 100px 80px 100px;
}



</style>
