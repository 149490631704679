<script setup lang="ts">
import { RouterView } from 'vue-router'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useAuthStore } from '@/stores/user'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { webSocketService } from '@/service/websocketService'
import AuthWrapper from '@/blocks/Modals/AuthModals/AuthWrapper.vue'

const toast = useToast()
const auth = useAuthStore()

const token = computed(() => auth.userInfo.accessToken)

const showToast = (msg) => {
  toast.add({ severity: 'info', summary: 'Info', detail: msg, life: 3000 })
}

watch(token,async () => {
  if (token.value) {
    await auth.getUserInfo()
    webSocketService.connect(auth.userInfo.accessToken)
    webSocketService.on(showToast)
  }
})

onMounted(async () => {
  if (token.value) {
    await auth.getUserInfo()
    webSocketService.connect(auth.userInfo.accessToken)
    webSocketService.on(showToast)
  }
})

onUnmounted(() => {
  webSocketService.disconnect()
})
</script>

<template>
  <div class="wrapper">
    <Toast />
    <RouterView />
    <auth-wrapper />
  </div>
</template>

<style>
.wrapper {
  display: flex;
  justify-content: center;
}

*::-webkit-scrollbar {
  width: 4px; /* Ширина всего элемента навигации */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(145, 145, 145, 1); /* Цвет бегунка */
  border-radius: 100px; /* Округление бегунка */
  border: 1px solid rgba(145, 145, 145, 1); /* Оформление границ бегунка */
}

:deep(.p-select-list-container) {
  background: grey !important;
}

.pi-heart:hover {
  animation: tilt-n-move-shaking 0.25s infinite;
}

.pi-spinner {
  animation: rotateSpinner 800ms linear infinite;
}

@keyframes tilt-n-move-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform:  rotate(0deg); }
  75% { transform:  rotate(-5deg); }
  100% { transform:  rotate(0deg); }
}

@keyframes rotateSpinner {
  to {
    transform: rotate(360deg);
  }
}

</style>
