<script setup lang="ts">
import Image from 'primevue/image';
import Palette2x2 from '../../../assets/customIcons/2x2.svg'
import Palette3x3 from '../../../assets/customIcons/3x3.svg'
import { useSearch } from '@/stores/search'
import { computed } from 'vue'

const { title, itemsCount } = defineProps({
  title: String,
  itemsCount: Number
})

const search = useSearch()

const generatedImage = computed(() => search.generatedImageLink)

function setNewCountOfItemsInARow(count: number): void {
  search.countOfItemInRow = count
}
</script>

<template>
  <div class="search-page-result-header-inner">
    <div class="search-page-result-left-content__wrapper">
      <div class="temp-wrap">
        <div class="search-page-result-header-title">{{ title }}</div>
        <div class="search-page-result-header-items-count">{{ itemsCount }} товаров найдено</div>
      </div>
      <div  class="image-name">
        <Image :src="generatedImage" alt="Image" width="50" preview />
      </div>
    </div>
    <div class="search-page-result-right-content__wrapper">
      <div class="chose-view-icon" @click="setNewCountOfItemsInARow(4)">
        <img :src="Palette2x2" alt="palette" />
      </div>
      <div class="chose-view-icon" @click="setNewCountOfItemsInARow(5)">
        <img :src="Palette3x3" alt="palette" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-page-result-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 60px;
}

.search-page-result-left-content__wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.search-page-result-header-title {
  font-size: 40px;
  line-height: 46px;
  color: rgba(61, 61, 61, 1);
}

.search-page-result-header-items-count {
  font-size: 24px;
  line-height: 28px;
  color: rgba(145, 145, 145, 1);
}

.search-page-result-right-content__wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pi-table {
  cursor: pointer;
}

.temp-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.chose-view-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.chose-view-icon:hover {
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.image-name {
  display: flex;
  align-items: center;
  gap:10px;
}

.generated-image {
  width: 50px;
}
</style>
