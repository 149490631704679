<script setup lang="ts">
import { computed, ref } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import FloatLabel from 'primevue/floatlabel'
import Checkbox from 'primevue/checkbox'
import { useAuthStore } from '@/stores/user'
import { useAuthModal } from '@/stores/authModal'
import Password from 'primevue/password'
import Message from 'primevue/message'

const name = ref('')
const usernameError = ref(' ')

const email = ref('')
const emailError = ref('')

const password = ref('')
const passwordError = ref('')

const checked = ref(false)


const disableSubmit = computed(() => {
  return email.value === '' || password.value === '' || name.value === '' || !!emailError.value.length || !!passwordError.value.length  || !!usernameError.value.length;

})

function loginHandler() {
  useAuthModal().showAuthModal('signIn')
}

const validateUsername = () => {
  usernameError.value = name.value.length >= 3 ? '' : 'Имя пользователя должно содержать не менее 3 символов.'
}

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailPattern.test(email.value)) {
    emailError.value = 'Введите корректный email адрес.'
  } else {
    emailError.value = ''
  }
}

const validatePassword = () => {
  passwordError.value = password.value.length >= 8 ? '' : 'Пароль должен содержать не менее 8 символов.'
}

const handleAccountCreate = async () => {
  const res = await useAuthStore().signUp({
    name: name.value,
    email: email.value,
    password: password.value
  })

  if(res.response.data.detail) {
    emailError.value = 'Подтвердите Email у администратора'
  }
}
</script>

<template>
  <div class="sign-up__content">
    <div class="sign-up__content__wrapper">
      <div class="sign-up__inputs">
        <div>
          <FloatLabel>
            <InputText
              v-model="name"
              @input="validateUsername"
              class="email"
              :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              }
            }"
            />
            <label for="email">Введи имя пользователя</label>
          </FloatLabel>
          <Message
            v-if="!!usernameError"
            severity="error" size="small"
            :pt="{
                     root:{
                       style:{
                         outline: 'none',
                       },
                     },
                     content:{
                       style:{
                         padding:'0',
                         background:'transparent',
                       }
                     },
                     text:{
                       style:{
                         paddingLeft:'16px',
                         fontSize:'12px',
                       },
                     }
                   }"
          >{{ usernameError }}
          </Message>
        </div>
        <div>
          <FloatLabel>
            <InputText
              v-model="email"
              @input="validateEmail"
              class="email"
              :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              }
            }"
            />
            <label for="email">Введи адрес электронной почты</label>
          </FloatLabel>
          <Message
            v-if="!!emailError"
            severity="error" size="small"
            :pt="{
                     root:{
                       style:{
                         outline: 'none',
                       },
                     },
                     content:{
                       style:{
                         padding:'0',
                         background:'transparent',
                       }
                     },
                     text:{
                       style:{
                         paddingLeft:'16px',
                         fontSize:'12px',
                       },
                     }
                   }"
          >{{ emailError }}
          </Message>
        </div>
        <div>
          <FloatLabel>
            <Password
              v-model="password"
              @input="validatePassword"
              class="password"
              type="password"
              :feedback="false"
              toggle-mask
              :pt="{
              root: {
                style: {
                  width: '100%',
                  borderRadius: '8px'
                }
              },

              pcInput: {
                root: {
                  style: {
                    width: '100%',
                    borderRadius: '8px'
                  }
                }
              }
            }"
            />
            <label for="password">Введите пароль</label>
          </FloatLabel>
          <Message
            v-if="!!passwordError"
            severity="error" size="small"
            :pt="{
                     root:{
                       style:{
                         outline: 'none',
                       },
                     },
                     content:{
                       style:{
                         padding:'0',
                         background:'transparent',
                       }
                     },
                     text:{
                       style:{
                         paddingLeft:'16px',
                         fontSize:'12px',
                       },
                     }
                   }"
          >{{ passwordError }}
          </Message>
        </div>
      </div>
      <div class="policy">
        <Checkbox
          v-model="checked"
          :pt="{
            root: {
              style: {
                width: '24px',
                height: '24px'
              }
            }
          }"
          :binary="true"
        />
        Я принимаю Политику конфиденциальности и Условия обслуживания
      </div>
    </div>
    <div class="sign-up__submit_btn__wrapper">
      <Button
        class="sign-up-submit"
        label="Создать аккаунт"
        :disabled="disableSubmit"
        @click="handleAccountCreate"
      />
    </div>
  </div>
</template>
<style scoped>
.sign-up__content {
  display: flex;
  border-radius: 40px;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}

.sign-up__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
}

.sign-up__content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sign-up__inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sign-up__inputs__wrapper {
  width: 100%;
}

.name,
.email,
.password {
  width: 100%;
}

.policy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 12px 0 40px 0;
}

.sign-up__submit_btn__wrapper {
  display: flex;
  width: 100%;
}

.sign-up-submit {
  width: 100%;
  border-radius: 100px;
}

.old_user {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
