import type { Directive } from 'vue';

export const vCopy: Directive = {
  mounted(el: HTMLElement, binding) {
    el.style.cursor = 'pointer';

    const handleClick = async () => {
      try {
        await navigator.clipboard.writeText(binding.value || el.innerText);

        // Optional: Add visual feedback
        const originalBackground = el.style.backgroundColor;
        el.style.backgroundColor = '#4CAF50';

        setTimeout(() => {
          el.style.backgroundColor = originalBackground;
        }, 200);
      } catch (error) {
        console.error('Failed to copy text:', error);
      }
    };

    el.addEventListener('click', handleClick);

    // Cleanup
    el.__vCopy__ = handleClick;
  },

  beforeUnmount(el: HTMLElement) {
    el.removeEventListener('click', el.__vCopy__);
    delete el.__vCopy__;
  }
}