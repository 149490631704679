<script setup lang="ts">
import Button from 'primevue/button'
import { onMounted, ref, watch } from 'vue'
import { getRecommendationsBanners } from '@/api/recommendations'
import BannersAItip from '@/blocks/HomeWrapper/Banners/BannersAItip.vue'
import { useRoute, useRouter } from 'vue-router'
import BannersListSkeleton from '@/blocks/HomeWrapper/Banners/BannersListSkeleton.vue'
import DeferredContent from 'primevue/deferredcontent'

const router = useRouter()
const route = useRoute()

const isBannersLoading = ref(false)

const bannersData = ref<any>([])
const lastBanner = ref([])

function handleBannerClick(bannerId) {
  router.push({
    path: `recommendations/banners/${bannerId}`
  })
}

async function loadBannersData() {
  isBannersLoading.value = true

  const res = await getRecommendationsBanners(route.query.audience ? route.query.audience : 'woman')
  bannersData.value = res.data.data
  lastBanner.value = bannersData.value.splice(4, 1)

  isBannersLoading.value = false
}

watch(
  route, async () => {
    if (route.query.audience) {
      await loadBannersData()
    }
  },
  { deep: true }
)


</script>

<template>
  <DeferredContent @load="loadBannersData">
    <div class="banners-wrapper">
      <banners-list-skeleton v-if="isBannersLoading" />
      <template v-else>
        <div class="banners-left-items__wrapper">
          <div
            v-for="item in bannersData"
            class="left-banner-item"
            @click="handleBannerClick(item.id)"
          >
            <div class="wrapper-for-ai-tips">
              <banners-a-itip :text="item.ai_notes[0]" />
            </div>
            <div class="banners-item-image">
              <img :src="item.image_url">
            </div>
            <div class="banners-content">
              <div class="banners-item-title">
                {{ item.name }}
                <i class="pi pi-arrow-right" />
              </div>
              <p class="banners-text">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>

        <div class="banners-right-items__wrapper">
          <div class="right-item__wrapper">
            <div
              class="right-banner-image-wrapper"
              @click="handleBannerClick(lastBanner[0]?.id)"
            >
              <img :src="lastBanner[0]?.image_url">
              <div class="right-banner-text">
                <p class="txt">
                  {{ lastBanner[0]?.name.toUpperCase() }}
                  <i
                    class="pi pi-arrow-right"
                    style="font-size: 1.5rem"
                  />
                </p>
              </div>
            </div>
            <div class="right-banner-text-content">
              <p class="text-top-content">
                Хочешь получать ещё
                <span style="color: rgba(147, 120, 101, 1)">более точные</span> подборки?
              </p>
              <div class="right-banner-bottom-content">
                <Button
                  as="router-link"
                  label="Уточнить вкусы"
                  to="/"
                  severity="info"
                  class="btn-left"
                />
                <p class="txt-right">
                  Позволь нашему ИИ-стилисту создавать ещё более точные и персонализированные подборки для
                  тебя. Пройди тест, чтобы мы могли лучше понять твой вкус и предпочтения.
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </DeferredContent>
</template>

<style scoped>
.right-banner-image-wrapper img {
  width: 100%;
  height: 100%;
}

.banners-item-image img {
  width: 100%;
  height: 100%;
}

.wrapper-for-ai-tips {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: absolute;
}

.banners-left-items__wrapper {
  display: flex;
  justify-content: space-around;
  width: 50%;
  flex-wrap: wrap;
  gap: 80px;
}

.left-banner-item {
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 40px);
  position: relative;
  gap: 12px;
  cursor: pointer;
}

.banners-item-image {
  max-width: 384px;
  max-height: 464px;
  height: 464px;
  border-radius: 40px;
  overflow: hidden;
  width: 100%;
}

.banners-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: flex-start;
}

.banners-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: rgba(61, 61, 61, 1);
}

.banners-text {
  padding: 0 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(115, 115, 115, 1);
}

.banners-right-items__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 40px);
}

.right-item__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.right-banner-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 860px;
  border-radius: 40px;
  background: grey;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.right-banner-text {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  font-weight: 400;
  font-size: 36px;
  line-height: 54px;
  color: rgba(255, 255, 255, 1);
}

.right-banner-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 64px;
}

.text-top-content {
  max-width: 500px;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: rgba(61, 61, 61, 1);
}

.right-banner-bottom-content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: center;
}

.btn-left {
  text-decoration: none;
}

.txt {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.txt-right {
  max-width: 440px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(145, 145, 145, 1);
}

.banners-wrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
}

@media (max-width: 1366px) {
  .banners-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 1366px) {
  .banners-left-items__wrapper {
    width: 100%;
  }

  .banners-right-items__wrapper {
    width: 100%;
  }

  .right-banner-image-wrapper {
    max-height: 280px;
  }

  .right-banner-image-wrapper img {
    object-fit: cover;
  }
}
</style>
