<script setup lang="ts">
import { Autoplay, Controller, FreeMode, Navigation } from 'swiper/modules'
import { onMounted, ref, watch } from 'vue'
import 'swiper/css'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { getPromotedList } from '@/api/recommendations'
import { useRoute } from 'vue-router'
import AdvetisingSkeleton from '@/blocks/HomeWrapper/AdvertisingItems/AdvetisingSkeleton.vue'

const route = useRoute()

const isAdvertisingListLoading = ref(false)

function addDimensions(
  elements: any,
  dimensions: { width: number; height: number }[]
): any {
  return elements.map((element, index) => {
    const dimensionIndex = index % dimensions.length;
    return {
      ...element,
      width: dimensions[dimensionIndex].width,
      height: dimensions[dimensionIndex].height,
    };
  });
}

async function loadAdvetisingItems() {
  isAdvertisingListLoading.value = true
  const res = await getPromotedList(30, route.query.audience)
  advertisingItems.value = addDimensions(res.data.data,dimensions)
  isAdvertisingListLoading.value = false
}

const dimensions = [
  { width: 380, height: 425 },
  { width: 280, height: 520 },
  { width: 446, height: 478 },
  { width: 264, height: 480 },
  { width: 380, height: 400 },
];


const swiperModules = [FreeMode, Autoplay, Navigation, Controller]

const advertisingItems = ref([])

watch(route,async () => {
    if (route.query.audience) {
      await loadAdvetisingItems()
    }
  },
  { deep: true }
)

onMounted(async () => {
  await loadAdvetisingItems()
})
</script>

<template>
  <div class="adv-wrapper">
    <advetising-skeleton v-if="isAdvertisingListLoading" />
    <div v-else class="swp-wrp">
      <swiper
        :loop="true"
        :free-mode="true"
        :space-between="20"
        :slides-per-view="'auto'"
        :speed="25000"
        :modules="swiperModules"
        :autoplay="{ disableOnInteraction: false }"
        class="company-carousel__container"
      >
        <swiper-slide
          v-for="(advItem, index) in advertisingItems"
          :key="index"
          class="company-carousel__item"
        >
          <div
            v-if="advItem?.image_url"
            class="adv-images__wrapper"
            :style="{
              width: advItem?.width + 'px',
              height: advItem?.height + 'px',
            }"
          >
            <img
              class="adv-images"
              :src="advItem.image_url"
            >
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style scoped>
.adv-wrapper {
  width: 100%;
  max-height: 420px;
  overflow: hidden;
}

.adv-images__wrapper {
  border-radius: 40px;
  overflow: hidden;
  cursor: pointer;
}

.adv-images {
  border-radius: 40px;
  transition: all ease-in-out 0.5s;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.adv-images:hover {
  transform: scale(1.2);
  transition: transform ease-in-out 0.5s;
  border-radius: 40px;
}

.company-carousel__item {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 40px;
  text-align: center;
  height: auto;
  max-height: 520px;
  overflow: hidden;
}
</style>

<style>
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
