<template>
  <div class="price-graph-inner">
    <div class="price-chart-controls-wrapper">
      <div>
        <label
          for="min"
          class="font-bold block mb-2"
        >От</label>
        <InputNumber
          v-model="forSlide[0]"
          input-id="min"
          :max="graphData.options.max"
          :min="graphData.options.min"
          placeholder="От"
          :min-fraction-digits="2"
          :max-fraction-digits="5"
          fluid
        />
      </div>
      <div>
        <label
          for="max"
          class="font-bold block mb-2"
        >До</label>
        <InputNumber
          v-model="forSlide[1]"
          input-id="max"
          :max="graphData.options.max"
          :min="graphData.options.min"
          placeholder="До"
          :min-fraction-digits="2"
          :max-fraction-digits="5"
          fluid
        />
      </div>
    </div>
    <canvas ref="chartCanvas" />
    <div class="test">
      <Slider
        v-model="forSlide"
        range
        :min="graphData.options.min"
        :max="graphData.options.max"
        :step="step/4"
        :pt="{
          root:{
            style:{
              background:'transparent'
            }
          },
          range: {
            style: {
              background: 'rgba(36, 49, 74, 1)',
              height: '4px'
            }
          }
        }"
        class="test"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import InputNumber from 'primevue/inputnumber'
import Slider from 'primevue/slider'
import { ref, onMounted, watch, computed, unref } from 'vue'
import { Chart, registerables } from 'chart.js'
import { useSearch } from '@/stores/search'

Chart.register(...registerables)

const search = useSearch()

const { graphData, indexOfCategory } = defineProps(['graphData', 'indexOfCategory'])

const test = ref(0)
const chartCanvas = ref(null)
let chartInstance = null

const step = computed(() => {
  return graphData.options.max / graphData.options.coordinates.length
})

const forSlide = ref([graphData.options.currentMin, graphData.options.currentMax])

const minPrice = computed(() => forSlide.value[0])
const maxPrice = computed(() => forSlide.value[1])

const labels = [1, 2, 3, 4, 5, 6, 7, 8]
let timeout = null

function createDebounce() {
  return function (fnc, delayMs) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, delayMs || 500)
  }
}

function handler() {
  search.changePriceFromUserMenu(minPrice.value, maxPrice.value, indexOfCategory)
}

onMounted(() => {
  if(!graphData.options.currentMin || !graphData.options.currentMax) {
    forSlide.value[0] = graphData.options.min
    forSlide.value[1] = graphData.options.max
    handler()
  }

  chartInstance = new Chart(chartCanvas.value, {
    type: 'scatter',
    data: {
      datasets: [
        {
          label: 'Количество товаров',
          data: graphData.options.coordinates,
          borderColor: 'rgba(162, 184, 233, 1)',
          backgroundColor: 'rgba(162, 184, 233, 1)',
          showLine: true,
          pointStyle: false,
          fill: true,
          tension: 0.4,
          segment: {
            borderColor: (ctx) => {
              const value = ctx.p0.parsed.x
              if (value < minPrice.value) return 'transparent'
              if (value >= maxPrice.value) return 'transparent'
              return 'rgba(162, 184, 233, 1)'
            },
            backgroundColor: (ctx) => {
              const value = ctx.p0.parsed.x
              if (value < minPrice.value) return 'rgba(162, 184, 233, 0.5)'
              if (value >= maxPrice.value) return 'rgba(162, 184, 233, 0.5)'
              return 'rgba(162, 184, 233, 1)'
            }
          }
        }
      ]
    },
    options: {
      responsive: true,
      scales: {
        x: {
          display: false,
          grid: {
            display: false // убрать линии сетки по оси X
          }
        },
        y: {
          display: false,
          grid: {
            display: false // убрать линии сетки по оси Y
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  })
})

watch(
  forSlide,
  () => {
    if (chartInstance) {
      chartInstance.update()
    }
    createDebounce()(() => {
      handler()
    }, 1000)
  },
  {
    deep: true
  }
)
</script>

<style>
.price-graph-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
}

.test {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  margin-top: -2px;
}

.price-chart-controls-wrapper {
  display: flex;
  padding: 12px;
  gap: 12px;
}

.p-inputnumber-input {
  max-width: 146px;
}

canvas {
  max-width: 100%;
}
</style>
