<script setup lang="ts">
import { ref } from 'vue'
import Popover from 'primevue/popover'
import WorldIcon from '@/components/UI/CustomIconsAndImages/WorldIcon.vue'
import InternationalisationContent from '@/blocks/Header/Actions/InternalisationSelector/InternationalisationContent.vue'

// TODO переделать оверлей и табвью под четвертую версию  вынести их стили в праймвью вынести иконки вынести текст убрать стили из не скопед style
const op = ref()

const toggle = (event) => {
  op.value.toggle(event)
}
</script>

<template>
  <div>
    <div
      class="language-icon"
      @click="toggle"
    >
      <world-icon />
    </div>

    <Popover
      ref="op"
      appendTo="self"
      :pt="{
        root: {
          style: {
            background: 'rgba(245, 245, 247, 1)',
            borderRadius: '20px',
            overlayArrowLeft: '260px !important',
            top:'',
          }
        },
        content: {
          style:{
          maxWidth: '320px',
          borderRadius: '20px',
          border: '1px solid rgba(215, 217, 219, 1)',
          padding: '8px '
          }
        }
      }"
    >
      <internationalisation-content />
    </Popover>
  </div>
</template>

<style scoped>
:deep(.p-overlaypanel:before) {
  border-width: 6px;
}

.language-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 28px;
  background-color: rgba(245, 245, 247, 0.1);
  cursor: pointer;
}

:deep(.p-popover) {
  top:initial !important;
}
</style>
