<script setup lang="ts">
import { ref } from 'vue'
import router from '@/router'
import SearchInput from '@/blocks/HomeWrapper/Search/SearchInputWrapper/SearchInput.vue'
import Arrow from '@/blocks/HomeWrapper/Search/Arrow.vue'

const emits = defineEmits(['search'])

const searchedText = ref('')
</script>

<template>
  <div class="search__wrapper">
    <div class="search_text__wrapper">
      <span class="title"> Найди свой стиль c FAIR </span>
      <span class="subtitle">
        Ищи идеальные товары, вводя текст, добавляя фото, или сочетая оба способа – находи всё, что
        нужно!
      </span>
    </div>
    <div class="wrap">
      <search-input />
      <Arrow />
    </div>
  </div>
</template>

<style scoped>
.search__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 980px;
  position: absolute;
  top: 200px;
  z-index: 7;
}

.search_text__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.search_text__wrapper .title {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  color: rgba(255, 255, 255, 1);
}

.search_text__wrapper .subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(145, 145, 145, 1);
}

.wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
</style>
