<template>
  <div class="home-page__wrapper">
    <home-page-header />
    <home-page-content />
    <home-page-footer />
  </div>
</template>

<script setup lang="ts">
import HomePageHeader from '@/blocks/Header/HeaderWrapper.vue'
import HomePageContent from '@/blocks/HomeWrapper/HomeWrapper.vue'
import HomePageFooter from '@/blocks/Footer/Footer.vue'
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/user'
import { useAuthModal } from '@/stores/authModal'
import router from '@/router'
import { webSocketService } from '@/service/websocketService'

const route = useRoute()
const auth = useAuthStore()
const authModal = useAuthModal()

function sendMsgHandl() {
  if (!auth.userInfo.accessToken) return
  webSocketService.send(auth.userInfo.accessToken, 'msk')
}

onMounted(async () => {
  if (route.params.verifyToken) {
    await auth.verifyUser(route.params.verifyToken).then(() => {
      router.push({ path: '/' })
    })
  }

  if (route.query.code) {
    await auth.exchangeCodeForTokens(route.query.code).then(() => {
      router.push({ path: '/' })
    })
  }

  if (route.params.resetToken) {
    authModal.showAuthModal('setNewPassword')
  }
})
</script>

<style scoped>
.home-page__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 245, 247, 1);
}
</style>
